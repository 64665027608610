<template>
  <div>
    <b-card
      no-body
      class="border mt-1"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <feather-icon
              icon="MapPinIcon"
              size="18"
            />
            <span class="align-middle ml-50 mr-1">Candidate Source</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-b-toggle.sidebar-candidate-catalog
                variant="primary"
                size="sm"
                @click="Create"
              >
                Add Candidate Source
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="getDataSource"
        :fields="fields"
      >
        <template #cell(Name)="data">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            toggle-class="NewPaddingDropdown"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-toggle.sidebar-candidate-catalog
              @click="updateCandidateSource(data.item.Id)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteSource(data.item.Id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BRow, BCol, BButton, BDropdown, BDropdownItem, VBToggle,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import catalogService from '@core/services/catalogs/catalogsService'

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    getDataSource: {
      type: Array,
      required: true,
    },
    setInfo: {
      type: Function,
      required: true,
    },
    getDataRefresh: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fields: [{ key: 'Name', label: 'Name' }, { key: 'Actions', sortable: true }],
      Data: {},
    }
  },
  mounted() {
  },
  methods: {
    Create() {
      this.Data = { catalog: 'source', info: null }
      this.setInfo(this.Data)
    },
    updateCandidateSource(id) {
      catalogService.getSpecificElementCatalog({
        table: 'source',
        id,
      }).then(response => {
        const info = response.data.data
        this.Data = { catalog: 'source', info }
        this.setInfo(this.Data)
      })
    },
    deleteSource(id) {
      Swal.fire({
        title: 'Attention',
        text: ' ¿Do you want to delete this Source?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'delete',
      }).then(result => {
        if (result.isConfirmed) {
          catalogService.deleteSpecificElementCatalog({
            table: 'source',
            id,
          }).then(() => {
            this.getDataRefresh('source')
            Swal.fire(
              'Success',
              'Candidate Save!',
              'success',
            )
          })
        }
      })
    },
  },

}
</script>

<style>
.NewPaddingDropdown{
  padding: 0px;
}
</style>
