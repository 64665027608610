import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class CatalogsService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    createElementCatalog(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.createElementCatalog, ...args)
    }

    getAllElementCatalog(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getAllElementCatalog, ...args)
    }

    getSpecificElementCatalog(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getSpecificElementCatalog, ...args)
    }

    editSpecificElementCatalog(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.editSpecificElementCatalog, ...args)
    }

    deleteSpecificElementCatalog(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.deleteSpecificElementCatalog, ...args)
    }
}

function useCatalogsService() {
  const catalogService = new CatalogsService()

  return {
    catalogService,
  }
}

const { catalogService } = useCatalogsService()
export default catalogService
