<template>
  <div>
    <b-card>
      <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >
        <!-- general tab -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Gender</span>
          </template>
          <gender-view
            :get-data-gender="DataGender"
            :set-info="sendInfoByCatalogs"
            :get-data-refresh="getSpecificCatalog"
          />
        </b-tab>
        <!-- general tab -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="DropletIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Candidate Aplication Status</span>
          </template>
          <candidate-aplication-status
            :get-data-application-status="DataApplicationStatus"
            :set-info="sendInfoByCatalogs"
            :get-data-refresh="getSpecificCatalog"
          />
        </b-tab>
        <!-- general tab -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="CircleIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Covid VACC</span>
          </template>
          <covid-vacc
            :get-data-covid-vacc="DataCovidVacc"
            :set-info="sendInfoByCatalogs"
            :get-data-refresh="getSpecificCatalog"
          />
        </b-tab>
        <!-- general tab -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="MapPinIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Candidate Source</span>
          </template>
          <candidate-source
            :get-data-source="DataSource"
            :set-info="sendInfoByCatalogs"
            :get-data-refresh="getSpecificCatalog"
          />
        </b-tab>
        <!-- general tab -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="MaximizeIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Areas</span>
          </template>
          <areas
            :get-data-area-suggested="DataAreaSuggested"
            :set-info="sendInfoByCatalogs"
            :get-data-refresh="getSpecificCatalog"
          />
        </b-tab>
        <!-- general tab -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="AwardIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">English Levels</span>
          </template>
          <english-levels
            :get-data-english-level="DataEnglishLevel"
            :set-info="sendInfoByCatalogs"
            :get-data-refresh="getSpecificCatalog"
          />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Interview By</span>
          </template>
          <interview-by
            :get-data-interview-by="DataInterviewValidator"
            :set-info="sendInfoByCatalogs"
            :get-data-refresh="getSpecificCatalog"
          />
        </b-tab>
      </b-tabs>
    </b-card>
    <side-bar-candidate-catalog
      :get-sidebar-info="SideBarinfo"
      :get-data-refresh="getSpecificCatalog"
    />
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import catalogsServices from '@core/services/catalogs/catalogsService'
import GenderView from './Gender.vue'
import CandidateAplicationStatus from './CandidateAplicationStatus.vue'
import CovidVacc from './CovidVacc.vue'
import CandidateSource from './CandidateSource.vue'
import Areas from './Areas.vue'
import EnglishLevels from './EnglishLevels.vue'
import SideBarCandidateCatalog from './SideBar/SideBarCatalogCandidateTrack.vue'
import InterviewBy from './InterviewBy.vue'
// :get-data="getData"
export default {
  components: {
    BCard,
    BTabs,
    BTab,
    GenderView,
    CandidateAplicationStatus,
    CovidVacc,
    CandidateSource,
    Areas,
    EnglishLevels,
    SideBarCandidateCatalog,
    InterviewBy,
  },
  data() {
    return {
      Catalogs: [
        'gender', 'applicationStatus', 'covidVacc', 'source', 'areaSuggested', 'englishLevel', 'interviewValidator',
      ],
      DataGender: [],
      DataApplicationStatus: [],
      DataCovidVacc: [],
      DataSource: [],
      DataAreaSuggested: [],
      DataEnglishLevel: [],
      DataInterviewValidator: [],
      SideBarinfo: {},
    }
  },
  mounted() {
  },
  created() {
    this.getAllCatalogs()
  },
  methods: {
    getAllCatalogs() {
      this.Catalogs.forEach(catalog => {
        this.getSpecificCatalog(catalog)
      })
    },
    /* eslint-disable */
    getSpecificCatalog(catalog) {
      catalogsServices.getAllElementCatalog({
        table: catalog,
      }).then(response => {
        const data = response.data.data
        switch (catalog) {
          case 'gender':
            this.DataGender = data
            break;
          case 'applicationStatus':
            this.DataApplicationStatus = data
            break;
          case 'covidVacc':
            this.DataCovidVacc = data
            break;
          case 'source':
            this.DataSource = data
            break;
          case 'areaSuggested':
            this.DataAreaSuggested = data
            break;
          case 'englishLevel':
            this.DataEnglishLevel = data
            break;
          case 'interviewValidator':
            this.DataInterviewValidator = data
            break;        
          default:
            break;
        }
      })
    },

    sendInfoByCatalogs(info) {
      this.SideBarinfo = info
    }
    /* eslint-enable */
  },

}
</script>

<style>

</style>
