<template>
  <b-sidebar
    id="sidebar-candidate-catalog"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @hidden="cleanCandidateSidebar"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ getSidebarInfo.info ? 'Edit': 'Add' }} {{ getSidebarInfo.catalog }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent
      >

        <b-form-group
          label="Name"
          label-for="Name"
        >
          <b-form-input
            v-model="Name"
            placeholder="Name"
            name="Name"
          />
        </b-form-group>
        <b-form-group
          v-if="getSidebarInfo.catalog === 'applicationStatus'"
          label="Select Color"
          label-for="Select Color"
        >
          <color-panel v-model="Color" />
        </b-form-group>
        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            @click="Save()"
          >
            {{ getSidebarInfo.info ? 'Edit': 'Add' }}
          </b-button>
          <b-button
            ref="closeSideBarCatalogsCandidateTracker"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Cancel
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
// import authService from '@core/services/auth/authService'
import catalogService from '@core/services/catalogs/catalogsService'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    getSidebarInfo: {
      type: Object,
      required: true,
    },
    getDataRefresh: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      Name: '',
      Color: '',
      Id: 0,
    }
  },
  watch: {
    getSidebarInfo(val) {
      if (val.info !== null) {
        this.Id = val.info[0].Id
        if (val.catalog === 'applicationStatus') {
          this.Color = val.info[0].Color
        } else {
          this.Color = ''
        }
        this.Name = val.info[0].Name
      } else {
        this.Name = ''
        this.Color = ''
        this.Id = 0
      }
    },
  },
  mounted() {
  },
  methods: {
    cleanCandidateSidebar() {
      this.Name = ''
      this.Color = ''
      this.Id = 0
    },
    Save() {
      let Text = ''
      if (this.Name === '') {
        Text += '- Name missing<br>'
      } else {
        Text += ''
      }
      if (this.getSidebarInfo.catalog === 'applicationStatus') {
        if (this.Color === '') {
          Text += '- Color missing<br>'
        } else {
          Text += ''
        }
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: `<div class="lbedo">${Text}</div>`,
          showConfirmButton: false,
        })
      } else if (this.getSidebarInfo.info === null) {
        Swal.fire({
          title: 'Attention',
          text: `¿Do you want to create this ${this.getSidebarInfo.catalog}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Create',
        }).then(result => {
          if (result.isConfirmed) {
            if (this.getSidebarInfo.catalog === 'applicationStatus') {
              catalogService.createElementCatalog({
                table: this.getSidebarInfo.catalog,
                name: this.Name,
                color: this.Color,
              }).then(() => {
                this.getDataRefresh(this.getSidebarInfo.catalog)
                this.$refs.closeSideBarCatalogsCandidateTracker.click()
              })
            } else {
              catalogService.createElementCatalog({
                table: this.getSidebarInfo.catalog,
                name: this.Name,
              }).then(() => {
                this.getDataRefresh(this.getSidebarInfo.catalog)
                this.$refs.closeSideBarCatalogsCandidateTracker.click()
              })
            }
          }
        })
      } else {
        Swal.fire({
          title: 'Attention',
          text: `¿Do you want to Edit this ${this.getSidebarInfo.catalog}?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Edit',
        }).then(result => {
          if (result.isConfirmed) {
            if (this.getSidebarInfo.catalog === 'applicationStatus') {
              catalogService.editSpecificElementCatalog({
                table: this.getSidebarInfo.catalog,
                id: this.Id,
                name: this.Name,
                color: this.Color,
              }).then(() => {
                this.getDataRefresh(this.getSidebarInfo.catalog)
                this.$refs.closeSideBarCatalogsCandidateTracker.click()
              })
            } else {
              catalogService.editSpecificElementCatalog({
                table: this.getSidebarInfo.catalog,
                id: this.Id,
                name: this.Name,
              }).then(() => {
                this.getDataRefresh(this.getSidebarInfo.catalog)
                this.$refs.closeSideBarCatalogsCandidateTracker.click()
              })
            }
          }
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
